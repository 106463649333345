import heroBgImg from './../assets/herobg.png';
function HeroSection() {
  return (
    <div id="hero-section" className="container-fluid pt-5 pb-5 h-auto" style={{
      background:  `url(${heroBgImg})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover'
    }}>
      <div className="row">
        <div className="col text-start">
          {/* <span className="fw-bold fs-1 m-4 text-white">MatchResume.in</span> */}
        </div>
        <div className="col"></div>
        <div className="col text-end">
          
        </div>
      </div>
      <div className="row mt-5">
        <div className="col">
          <div className="container">
            <div className="row">
              <div id="hero-info" className="col-lg-6 com-sm-12 p-4 text-white" style={{
                backgroundColor: 'rgba(128, 128, 128, 0.39)',
                borderRadius: '51px'
              }}>
                <h1 className="fw-bold">Find the Perfect Match</h1>
                <p className="fs-3">
                  Effortlessly Match Resumes to Job Descriptions
                </p>
                <hr />
                <p className="fs-5">
                  Streamline your hiring process with our AI-powered resume
                  scanning tool. Get accurate match scores and save time.
                </p>
                <p className="fw-bold">Features Section:</p>
                <ul className="text-red">
                  <li>
                    Accurate Matching: AI-driven technology that provides
                    precise match scores.
                  </li>
                  <li>
                    Time-Saving: Quickly filter through large volumes of
                    resumes.
                  </li>
                  <li>
                    User-Friendly Interface: Easy-to-use dashboard for both
                    recruiters and candidates.
                  </li>
                  <li>
                    Detailed Reports: Insightful reports to make informed hiring
                    decisions.
                  </li>
                </ul>
              </div>
              <div className="col"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default HeroSection;
