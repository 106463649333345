interface pageHeadingProps {
  title: string;
  gradientColors: [string, string];
}
const PageHeading = ({ title, gradientColors }: pageHeadingProps) => {
  const backgroundStyle: React.CSSProperties = {
    background: `radial-gradient(circle, ${gradientColors[0]} 0%, ${gradientColors[1]} 78%)`,
    textAlign: "center",
    color: "#fff",
    padding: "50px 0 50px 0",
    borderRadius: "0px",
    margin: "0px 0",
    textTransform: "capitalize",
  };

  return (
    <div style={backgroundStyle}>
      {title == "about-sp-group" ? (
        <h1>About SP Group</h1>
      ) : (
        <h1 style={{ margin: 0 }}>{title.replaceAll("-", " ")}</h1>
      )}
    </div>
  );
};

export default PageHeading;
