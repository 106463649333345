import Slider from "react-slick";

import FooterSection from "./footer";
import HeroSection from "./hero";
import PricingSection from "./pricing";

import atsOptimiseResumeImg from "./../assets/ats-optimise-resume.png";
import scanResumeImg from "./../assets/scan-resume.png";
import privacyImg from "./../assets/privacy.png";
import img1 from "./../assets/user1.png";
import img2 from "./../assets/user2.png";
import img3 from "./../assets/user3.png";
import img4 from "./../assets/user4.png";
import img5 from "./../assets/user5.png";
import img6 from "./../assets/user6.png";
import img7 from "./../assets/user7.png";
import img8 from "./../assets/user8.png";
import AppNavBar from "../components/app-bar";
import useStore from "./../store";

function HomePage() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 6,
    responsive: [
      {
        breakpoint: 600,
        vertical: true,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  const MissionList = [
    {
      img: img1,
      text: "MatchResume is a game-changer! Job hunting used to be exhausting, but their job scanner made it so much easier. I uploaded my resume and got instant insights on how to optimize it for ATS systems. The match score and detailed report helped me tailor my applications perfectly. Plus, knowing that my data is securely protected and can be deleted anytime gives me total peace of mind. Highly recommend!",
    },
    {
      img: img2,
      text: "I was skeptical at first, but MatchResume exceeded my expectations. The detailed job match report helped me tweak my resume perfectly, and the ATS insights made a huge difference. Plus, their focus on privacy is top-notch.",
    },
    {
      img: img3,
      text: "Thanks to MatchResume, I finally started getting interview calls! The scanner is incredibly easy to use, and the results are instant. I love that my data is secure and that I can delete it anytime. A fantastic tool!",
    },
    {
      img: img4,
      text: "MatchResume saved me so much time and stress. The keyword suggestions were accurate, and I could see my resume's strengths and weaknesses at a glance. Knowing my data is safe and secure is a huge plus.",
    },
    {
      img: img5,
      text: "This tool is a game-changer for job seekers. MatchResume's match score and report gave me clear direction on how to improve my resume for each job. I also appreciate their commitment to privacy and data security.",
    },
    {
      img: img6,
      text: "I can't recommend MatchResume enough! The instant match score and keyword recommendations helped me tailor my resume perfectly for each job application. It's a powerful tool that takes the guesswork out of the process.",
    },
    {
      img: img7,
      text: "MatchResume is an essential tool for anyone applying through ATS systems. The insights it provided were invaluable, and the privacy features gave me peace of mind. I've already landed two interviews thanks to their suggestions!",
    },
    {
      img: img8,
      text: "Using MatchResume was a breeze, and the results speak for themselves. The match score gave me confidence that my resume was optimized, and the job match report was incredibly detailed. I love how secure and private my data is with them.",
    },
  ];
  return (
    <div>
      <AppNavBar />
      <HeroSection />
      <div className="container pt-5 pb-5">
        <div className="row small-screen-reverse">
          <div className="col-lg-6 col-sm-12">
            <h1 className="text-red fw-bold fs-1">
              Boost Your Interview Chances with ATS-Optimized Resumes
            </h1>
            <p className="text-black fs-4 pt-2">
              Applicant Tracking Systems (ATS) are essential recruitment tools
              that organizations use to screen and filter potential candidates.
              Securing an interview largely depends on your resume's ability to
              pass through these systems. Our advanced tool replicates the
              keyword-ranking algorithms used by leading ATS, ensuring your
              resume stands out and gets the attention it deserves.
            </p>
          </div>
          <div className="col text-center">
            <img
              className="w-50 pb-5"
              src={atsOptimiseResumeImg}
              alt="Boost Your Interview Chances with ATS-Optimized Resumes"
            />
          </div>
        </div>
        <div className="row mt-5 d-flex">
          <div className="col-lg-6 col-sm-12 order-2">
            <h1 className="text-red fw-bold fs-1">
              Save Time with Our Job Scanner: Find the Best Keywords for Your
              Resume
            </h1>
            <p className="text-black fs-4 pt-2">
              Job hunting can be time-consuming, but we've made it easier! Our
              job scanner has analyzed thousands of job descriptions to pinpoint
              the most effective keywords for your resume. Using the right
              keywords is crucial when applying through an applicant tracking
              system. Simply upload your resume and job description, and receive
              an instant match score along with a detailed job match report.
            </p>
          </div>
          <div className="col text-center order-1">
            <img
              className="w-50 pb-5"
              src={scanResumeImg}
              alt="Save Time with Our Job Scanner: Find the Best Keywords for Your Resume"
            />
          </div>
        </div>
        <div className="row mt-5 small-screen-reverse">
          <div className="col-lg-6 col-sm-12">
            <h1 className="text-red fw-bold fs-1">
              Your Privacy Matters: Secure Data Protection and Full Control Over
              Your Resume
            </h1>
            <p className="text-black fs-4 pt-2">
              We prioritize your privacy and go to great lengths to protect your
              data. With secure servers, complete transparency, and strict
              privacy policies, your resume is never shared with anyone. You
              also have the option to request the deletion of your data from our
              servers at any time.
            </p>
          </div>
          <div className="col text-center">
            <img
              className="w-50 pb-5"
              src={privacyImg}
              alt="Your Privacy Matters: Secure Data Protection and Full Control Over Your Resume"
            />
          </div>
        </div>
      </div>
      <div className="container pb-5">
        <div className="row">
          <div className="col text-center">
            <h1 className="fs-1 fw-bold text-red">Our Mission</h1>
            <p className="fs-6 text-black">
              We help our users achieve their highest goals, no matter where
              they are on their journey.
            </p>
          </div>
        </div>
        <div className="row mt-5">
          <div id="home-slider" className="col">
            <Slider {...settings}>
              {MissionList.map((mission, index) => (
                <div className="grid border rounded p-3 gap-3 user-feedback shadow-sm">
                  <div className="row">
                    <div className="col-lg-3 col-sm-4 text-center d-flex align-items-center justify-content-center">
                      <img
                        className="w-auto"
                        src={mission.img}
                        alt="User Image"
                      />
                    </div>
                    <div className="col-lg-9 col-sm-7">
                      <h1 className="text-black fs-6 pt-2">{mission.text}</h1>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
      <PricingSection />
      <FooterSection />
    </div>
  );
}
export default HomePage;
