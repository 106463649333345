import AppNavBar from "../components/app-bar";
import MarkdownRenderer from "../components/markdown";
import useStore from "../store";
import FooterSection from "./footer";
import PageHeading from "./page-heading";
interface PagePros {
  id: string;
}

function Page({ id }: PagePros) {
  const supportEmail = useStore((state) => state.supportEmail);
  let markdownText = ``;

  switch (id) {
    case "privacy-policy":
      markdownText = `

MatchResume ("we," "our," "us") is committed to protecting your personal data. This policy explains how we collect, use, and safeguard your information.

#### 1\. Information We Collect

-   Personal Data: Name, email address, phone number, payment details.

-   Resume Data: Uploaded resumes, job descriptions, and related documents.

-   Usage Data: Browser type, IP address, cookies, and analytics information.

#### 2\. Use of Information

We use your data to:

-   Provide and improve our services.

-   Analyze user preferences and improve functionality.

-   Send notifications, updates, and promotional content (you can opt-out).

#### 3\. Data Sharing

We do not sell your data. However, we may share it with:

-   Service providers (e.g., hosting, payment processing) for operational purposes.

-   Legal authorities if required by law.

#### 4\. Your Rights

-   Access, correct, or delete your data by contacting us at support@matchresume.in.

-   Opt-out of marketing communications.`;
      break;
    case "refund-policy":
      markdownText = `

We strive to deliver the best service and provide transparent policies.

#### 1\. Cancellations

-   Services can be canceled anytime, no refund will provided.

-   Subscription plans can be canceled anytime, but no refunds will be issued for partially used plans.

#### 2\. Refund Policy

-   Full refunds are issued if services are not delivered as promised.

-   Refund requests must be made within 7 days of purchase.

#### 3\. Non-Refundable Services

-   Services already rendered, such as completed resume reviews, are non-refundable.`;
      break;
    case "shipping-and-delivery-policy":
      markdownText = `

For digital services provided by MatchResume:

#### 1\. Delivery Method

-   All digital products and services (e.g., resume scans, match reports) are delivered electronically via the email/Web application provided during registration.

#### 2\. Delivery Timeline

-   Standard delivery is completed within 24 hours after purchase, subject to workload.

#### 3\. Delivery Issues

-   Contact [${supportEmail}](mailto:${supportEmail}) if delivery fails due to technical issues. must report within 7 working days.`;
      break;
    case "terms-and-conditions":
      markdownText = `

By accessing MatchResume, you agree to these Terms and Conditions.

#### 1\. Service Use

-   Services are for personal or recruitment purposes only.

-   Users must provide accurate information. Misuse of the platform may result in termination of access.

#### 2\. Intellectual Property

All content, tools, and software on MatchResume are owned by us. Copying or redistributing is prohibited.

#### 3\. Payment and Billing

-   Fees for services must be paid upfront.

-   MatchResume reserves the right to modify pricing at any time.

#### 4\. Limitation of Liability

We are not liable for indirect damages or losses caused by your use of our services.

#### 5\. Modifications

We may update these terms periodically. Continued use of the website constitutes acceptance of changes.`;
      break;
    case "about-sp-group":
      markdownText = `
At **SP Group**, we don't just build software---we build possibilities. As a premier software development company, we bring your ideas to life by delivering **customized, on-demand solutions** that fit your exact requirements. Whether you are an ambitious startup, a mid-sized enterprise, or a global corporation, we provide the tools and expertise to transform your digital goals into reality.

* * * * *

**Who We Are**
--------------

At SP Group, we are more than just developers---we are innovators, problem-solvers, and trusted partners. Our business was built on the philosophy that **technology should work for you, not the other way around**. Every company is unique, and so are its challenges and opportunities. That's why we deliver custom-tailored software solutions that align perfectly with your needs and help drive measurable results.

Our team is comprised of highly skilled professionals with deep expertise in **software development, app creation, AI technologies, and process automation**. We don't settle for the ordinary. Instead, we push boundaries to deliver solutions that are **scalable, secure, and future-proof**, helping you stay ahead in today's rapidly changing digital landscape.

* * * * *

**Our Core Services**
---------------------

### **1\. App Development**

In today's connected world, mobile and web applications are no longer optional---they are essential tools for success. At SP Group, we specialize in developing intuitive, user-friendly, and high-performance applications for businesses across industries.

-   **Mobile App Development:** Whether it's iOS, Android, or cross-platform applications, we create sleek, responsive apps that deliver a seamless user experience. From ideation to deployment, we work with you to turn your vision into reality.
-   **Web App Development:** Our team designs and builds modern, scalable, and secure web applications that help you engage your audience and drive business growth.
-   **Custom Solutions:** We don't believe in one-size-fits-all approaches. Our apps are tailored to meet your specific goals, ensuring you get the results you need.

Every app we deliver is designed with your **customers' experience** in mind. From smooth navigation to stunning visuals, we craft apps that don't just perform well---they impress.

* * * * *

### **2\. AI Solution Development**

Artificial Intelligence is revolutionizing industries, opening up new possibilities for automation, insights, and growth. At SP Group, we harness the power of **AI** to create solutions that transform the way you do business.

-   **Machine Learning Models:** We design machine learning solutions to help your business make data-driven decisions, identify patterns, and predict outcomes with precision.
-   **Natural Language Processing (NLP):** Improve customer interactions and automate workflows with NLP-powered tools, such as chatbots, sentiment analysis, and language-based analytics.
-   **Computer Vision:** From facial recognition to object detection, our computer vision solutions empower businesses with smarter tools for automation and insights.
-   **AI-Powered Automation:** Automate repetitive tasks, optimize processes, and increase efficiency with AI-driven tools tailored to your workflows.

With SP Group's AI services, you can leverage advanced technology to boost productivity, reduce costs, and unlock unprecedented opportunities for innovation.

* * * * *

### **3\. Custom Software Solutions**

No two businesses are the same, and their software requirements shouldn't be either. At SP Group, we specialize in delivering **custom software solutions** that address your unique business challenges.

-   **Enterprise Software Development:** From ERP systems to CRM tools, we create enterprise-grade software that helps streamline your operations and increase efficiency.
-   **Scalable Cloud Solutions:** We design cloud-based systems that allow your business to scale effortlessly while maintaining security and performance.
-   **Legacy System Modernization:** Outdated systems holding you back? We help businesses migrate to modern, scalable, and future-ready platforms seamlessly.
-   **API Integrations:** Connect your tools and platforms with powerful APIs that enable seamless data flow and automation across systems.

We work closely with you to understand your workflows, pain points, and objectives, ensuring the software we develop solves your problems, not adds to them.

* * * * *

**Why Choose SP Group?**
------------------------

### **1\. Customization at Its Best**

We don't believe in cookie-cutter solutions. Your business is unique, and your software should be too. Our team takes the time to **understand your requirements**, ensuring that every solution is tailor-made to align with your vision, goals, and challenges.

### **2\. Client-Centric Approach**

At SP Group, you are at the center of everything we do. From the initial consultation to project delivery and beyond, we work in close collaboration with you to ensure the solutions we develop deliver real value. Your success is our priority.

### **3\. Cutting-Edge Technology**

We are always on the forefront of technological advancements. Whether it's AI, cloud computing, or modern development frameworks, we use the latest tools and technologies to deliver solutions that are **robust, scalable, and future-ready**.

### **4\. Agile Development Process**

Our agile approach ensures that projects are delivered on time without compromising quality. By breaking down projects into phases and conducting regular reviews, we adapt to changes quickly while keeping you informed every step of the way.

### **5\. A Team of Experts**

Our team is made up of talented developers, designers, AI engineers, and project managers who are passionate about what they do. With a strong focus on creativity, technical excellence, and client satisfaction, we deliver solutions that exceed expectations.

### **6\. Long-Term Partnerships**

We don't just deliver software and walk away. At SP Group, we build lasting partnerships with our clients, offering ongoing support, updates, and improvements to keep your systems performing at their best.

* * * * *

**Our Process**
---------------

At SP Group, we follow a streamlined process to ensure that every project is executed flawlessly, from start to finish:

### **1\. Discovery and Consultation**

We start by understanding your business, challenges, and goals. Through in-depth consultations, we identify the key requirements and opportunities to build a solution that drives real results.

### **2\. Planning and Strategy**

Once we understand your needs, we create a detailed plan that outlines the project scope, timeline, and deliverables. Our strategy ensures that the solution is aligned with your vision and objectives.

### **3\. Design and Development**

Our expert designers and developers get to work, bringing your vision to life. We combine creativity, user-centric design, and cutting-edge technologies to build software that performs flawlessly and looks exceptional.

### **4\. Testing and Quality Assurance**

Before launch, we conduct rigorous testing to ensure that the solution is free of bugs, meets performance benchmarks, and delivers a seamless user experience.

### **5\. Deployment and Launch**

Once the solution is ready, we handle the deployment process with precision, ensuring a smooth transition and minimal disruption to your operations.

### **6\. Support and Maintenance**

Our partnership doesn't end with deployment. We offer continuous support, updates, and maintenance to ensure your software evolves with your business needs.

* * * * *

**Industries We Serve**
-----------------------

At SP Group, we work with clients across a wide range of industries, including:

-   **Healthcare:** Innovative healthcare applications and AI-powered solutions for better patient care and operational efficiency.
-   **Finance:** Custom fintech solutions, AI-driven analytics, and secure applications for financial institutions.
-   **Retail & E-commerce:** Intuitive apps, AI tools, and scalable platforms to enhance customer experiences and drive sales.
-   **Education:** Learning management systems, virtual classrooms, and AI-based solutions to revolutionize education.
-   **Manufacturing:** Automation tools, AI-driven insights, and process optimization software to improve efficiency.

No matter your industry, we tailor our solutions to fit your specific requirements and business goals.

* * * * *

**Let's Build the Future Together**
-----------------------------------

At SP Group, we believe that technology has the power to transform businesses, industries, and lives. With our custom software development, app creation, and AI-powered solutions, we help you turn your boldest ideas into reality.

Partnering with us means:\n
✅ Gaining access to a team of skilled professionals.\n
✅ Leveraging cutting-edge tools and technologies.\n
✅ Receiving solutions tailored to your exact needs.\n
✅ Staying competitive in a fast-paced digital world.

Your business is unique, and your software should reflect that. Let's collaborate to create technology that works **for you**---not against you.

* * * * *

**Get in Touch**
----------------

Are you ready to take your business to the next level? Connect with us today to explore how SP Group can empower your business with innovative, custom software solutions.

📞 **Contact us** at +91 82968 24563\n
📧 **Email us** at support@matchresume.in\n
🌐 Visit our website: https://matchresume.in

**Your Vision. Our Innovation. Let's Build the Future Together.**
`;
      break;
    case "hire-resume-consultant":
      markdownText = `**Hire a Professional Resume Consultant**

At SP Group, we offer specialized **Resume Consulting Services** designed to help you stand out in today's competitive job market. Our professional consultants craft resumes that highlight your unique skills, experience, and achievements, ensuring they align perfectly with industry standards and employer expectations.

Whether you are a recent graduate, mid-career professional, or executive-level candidate, we provide tailored solutions to showcase your strengths and improve your chances of landing your dream job.

**Our Services Include:**

-   Customized resume creation and optimization.
-   Keyword optimization to meet Applicant Tracking System (ATS) requirements.
-   Expert guidance on career achievements and strengths.
-   Cover letter writing and LinkedIn profile enhancement.

With SP Group's **Resume Consulting Services**, we help you make a lasting impression and get noticed by recruiters.

**Your Career. Our Expertise. Let's Unlock Your Potential.**`;
      break;
    default:
      markdownText = `This Page is not available, If you have any questions, feel free to send us an email at [${supportEmail}](mailto:${supportEmail}).`;
  }
  const contentDivStyle: React.CSSProperties = {
    background: `white`,
    textAlign: "left",
    padding: "80px 0 80px 0",
    minHeight: "500px",
  };
  return (
    <div className="">
      <AppNavBar />
      <PageHeading
        title={id}
        gradientColors={["rgba(54,20,124,1)", "rgba(68,26,158,1)"]}
      />
      <div className="container">
        <div className="row">
          <div className="col dynamic-page" style={contentDivStyle}>
            <MarkdownRenderer content={markdownText} />
          </div>
        </div>
      </div>

      <FooterSection />
    </div>
  );
}
export default Page;
