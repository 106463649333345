import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import "./custom.scss";
import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import { CssBaseline } from "@mui/material";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Auth from "./auth";
import HomePage from "./website/home";
import Page from "./website/page";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Suspense>
        <HomePage />
      </Suspense>
    ),
  },
  {
    path: "/privacy-policy",
    element: (
      <Suspense>
        <Page id="privacy-policy" />
      </Suspense>
    ),
  },
  {
    path: "/refund-policy",
    element: (
      <Suspense>
        <Page id="refund-policy" />
      </Suspense>
    ),
  },
  {
    path: "/shipping-and-delivery-policy",
    element: (
      <Suspense>
        <Page id="shipping-and-delivery-policy" />
      </Suspense>
    ),
  },
  {
    path: "/terms-and-conditions",
    element: (
      <Suspense>
       <Page id="terms-and-conditions" />
      </Suspense>
    ),
  },
  {
    path: "/contact",
    element: (
      <Suspense>
        <Page id="contact" />
      </Suspense>
    ),
  },
  {
    path: "/about-sp-group",
    element: (
      <Suspense>
        <Page id="about-sp-group" />
      </Suspense>
    ),
  },
  {
    path: "/hire-resume-consultant",
    element: (
      <Suspense>
        <Page id="hire-resume-consultant" />
      </Suspense>
    ),
  },
  {
    path: "/auth",
    element: <Auth />
  },
  {
    path: "*",
    element: <h1>Page not found</h1>,
  },
]);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <RouterProvider router={router} />
    </ThemeProvider>
  </React.StrictMode>
);
