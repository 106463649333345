function FooterSection() {

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="container bg-black text-white"  style={{paddingTop: "20px"}}>
          <div className="row">
            <div className="col">
              <hr />
            </div>
          </div>
          <div className="row mt-5 mb-5">
            <div className="col-lg-3"></div>
            <div className="col-lg-3 highlight-border-left">
              <h1 className="fs-4">Policies</h1>
              <ul>
                <li>
                  <a href="/privacy-policy">
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a href="/refund-policy">
                    Refund Policy
                  </a>
                </li>
                <li>
                  <a href="/shipping-and-delivery-policy">
                    Shipping Policy
                  </a>
                </li>
                <li>
                  <a href="/terms-and-conditions">
                    Terms of Service
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 highlight-border-left highlight-border-right">
              <h1 className="fs-4">Quick Links</h1>
              <ul>
                <li><a href="/contact">Contact Us</a></li>
                <li><a href="/about-sp-group">About SP Group</a></li>
                <li><a href="/hire-resume-consultant">Hire Resume Consultant</a></li>
              </ul>
            </div>
            <div className="col-lg-3"></div>
          </div>
          <div className="row text-center">
            <div className="col">
              <p>
                &copy; <span id="year">2024</span> matchresume.in made with
                &#x2764; by SP Group.
              </p>
              <p>
                GSTIN: 08BHKPK7501A1ZO | support@matchresume.in | &#x1F4AC; +91
                82968 24563
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default FooterSection;
