function PricingSection() {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="container bg-black text-white">
          <div className="row text-center">
            <div className="col-2"></div>
            <div className="col">
              <h1 className="fs-1 fw-bold mt-5">Feature for Recruiters</h1>
              <h1 className="fs-1 fw-bold text-green">
                Add a new Hiring Process tool with MatchResume
              </h1>
              <p className="fs-5 mt-4">
                Streamline your hiring process and uncover top talent with
                MatchResume's cutting-edge resume analysis and ATS optimization
                tools. Our platform automatically ranks and matches candidate
                resumes to job descriptions, providing detailed reports and
                insights that help you make informed hiring decisions. With
                MatchResume, recruiters can save time, increase the accuracy of
                candidate searches, and ensure data privacy and security
                throughout the recruitment process.
              </p>
            </div>
            <div className="col-2"></div>
          </div>
          <div className="row text-center mt-5 mb-5">
            <div className="col">
              <h1 className="fs-1 text-white fw-bold">Our Pricing</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-2"></div>
            <div className="col-lg-3 shadow-sm border rounded p-5 text-white plan-pkg">
              <h1 className="fs-4 fw-bold">Basic Plan</h1>
              <p>For Individuals, looking to Improve resume</p>
              <p className="fw-bold fs-2">499 INR/Month</p>
              <p>Billed Monthly</p>
              <div className="row text-center mt-2 mb-2">
                <div className="col">
                  <a href="#" className="btn btn-primary">
                    Buy Now
                  </a>
                </div>
              </div>
              <ul className="mt-3">
                <li>10 Resume Scan Available per month</li>
                <li>Access to Job Tracker</li>
                <li>Download ATS Friendly Resume</li>
              </ul>
            </div>
            <div className="col-lg-1"></div>
            <div id="pro-plan" className="col-lg-3 shadow-sm border rounded p-5 plan-pkg">
              <h1 className="fs-4 fw-bold">Pro Plan</h1>
              <p>For Recruiters, looking to filter Candidates</p>
              <p className="fw-bold fs-2">1499 INR/Month</p>
              <p>Billed Annually</p>
              <div className="row text-center mt-2 mb-2">
                <div className="col">
                  <a href="#" className="btn btn-primary">
                    Buy Now
                  </a>
                </div>
              </div>
              <ul className="mt-3">
                <li>500 Resume Scan Available per month</li>
                <li>Manage Multiple Job Boards</li>
                <li>Download ATS Friendly Resume</li>
              </ul>
            </div>
            <div className="col-lg-2"></div>
          </div>
          <div className="row text-center mt-3">
            <div className="col">
              <p className="fs-6">
                Note: Increase your resume scan limit with our Pro Plan. Get 500
                additional scans for just 1000 INR.
              </p>
              <h1 className="fs-1 mt-5">
                Join Now to Simplify Your Hiring Process—Sign Up for MatchResume
                Today!
              </h1>
              <a href="#" className="btn btn-primary mt-5 mb-5">
                SignUp/Login
              </a>
            </div>
          </div>   
        </div>
      </div>
    </div>
  );
}
export default PricingSection;
