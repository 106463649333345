import { create } from "zustand";

interface StoreState {
  appName: string;
  supportEmail: string;
}

const useStore = create<StoreState>((set) => ({
  appName: "Matchresume",
  supportEmail: "support@matchresume.in",
}));

export default useStore;
