import React, { useEffect } from "react";
import { useNavigate, useSearchParams, useParams } from "react-router-dom";

export default function Auth() {
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    console.log(token);
    if (token) {
      localStorage.setItem("authToken", token);
      navigate("/dashboard");
    } else {
      console.error("Token not found in URL");
    }
  }, [navigate]);
  return <></>;
}
